import React from 'react';
import { Seo, OrderSuccess, Feed } from 'components';
import { protfolioFeedKeys } from '../components/Projects/Portfolio/utils';

export default () => {
  return (
    <>
      <Seo title={'Заявка'} />
      <OrderSuccess />
      <Feed
        list={protfolioFeedKeys.map((item) => {
          return ['projects', item].join('/');
        })}
      />
    </>
  );
};
